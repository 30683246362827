@import '~antd/dist/antd.dark.less';

@body-background: #1d2031;
//@layout-header-background: #1f1f1f;
@layout-header-background: #1d2031;
@primary-color: #8996da;
@link-color: #1890ff;
@success-color: #4caf50;
@warning-color: #ffc107;
@error-color: #ff0743;
@font-size-base: 14px;
//@heading-color: rgba(0, 0, 0, 0.85); // heading text color
//@text-color: rgba(0, 0, 0, 0.65); // major text color
//@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
//@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
//@border-radius-base: 2px; // major border radius
//@border-color-base: #d9d9d9; // major border color
//@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@layout-sider-background: #161825;
@menu-bg: #161825;
@menu-item-active-bg: #8996da;
@menu-item-padding: 0;
@menu-item-active-border-width: 0;

@alert-info-bg-color: rgb(70 76 113);
@alert-info-border-color: @primary-color;

@btn-font-weight: 700;
@btn-border-radius-base: 10px;
@btn-border-width: 0;
@select-dropdown-bg: #161825;
@select-background: #161825;
@select-border-color: none;
@select-item-active-bg: @menu-item-active-bg !important;
@selection-item-padding: 24px;
@table-bg: transparent;
@table-header-bg: #161825;
@table-row-hover-bg: fade(@primary-color, 25%);
@border-color-base: rgba(63, 62, 68, 0.7);
@border-color-split: rgba(63, 62, 68, 0.7);

@input-placeholder-color: #8d8c8c;

@typography-title-margin-bottom: 0;
@link-color: @primary-color;
@layout-header-padding: 0;
@font-size-base: 14;
@icon-color: @primary-color;

@modal-header-bg: @layout-sider-background;
@modal-content-bg: @body-background;
@border-radius-base: 8px;
@checkbox-border-radius: 2px;

.ant-btn-default {
  background-color: #242735;
  color: #9c9c9c;
  border: none;
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}

div.ant-modal-footer {
  border-top: 0;
}

.ant-typography ul {
  list-style-type: disc;
  li {
    padding: 0;
  }
}

a.ant-btn {
  padding-top: 4px !important;
  line-height: inherit;
}

.ant-radio-group {
  font-size: 14px;
}
